import React, {MouseEventHandler, useState} from "react"
import "./index.scss"
import {CommonButton, Loading} from "../Button";
import {shortenString} from "../../utils/util";
import {mainApi} from "../../api/main";
import {message, Tooltip} from "antd";
import {useInit} from "../../utils/useInit";
import {useMinersStore} from "../../redux/features/miners";
import {Principal} from "@dfinity/principal";
import useWindowSize from "../../hook/Resize";
import {Content} from "../Content";
import {useDashboardStore} from "../../redux/features/dashboard";


const title = ["Canister Id", "Cycle Balance", "Block Mined", "Power", "Staking Status"]
const cycleDeleted = 0.2 * 1e12 // 0.2 T
export const Mining = () => {
  const {width} = useWindowSize();
  const {setLoading, refresh, principal} = useInit()
  const {stakingBalances, stakingPoints} = useMinersStore()
  const [upgradeLoading, setUpgradeLoading] = useState<number[]>([])

  const stakingStatus = React.useMemo(() => {
    if (!stakingPoints || !stakingBalances) return undefined
    return stakingPoints.map((v, k) => {
      if (v === 0 && stakingBalances[k] === 0) return "None"
      if (v === 0 && stakingBalances[k] > 0) return "In Effect"
      return "Active"
    })
  }, [stakingPoints, stakingBalances])

  const upgrade = async (isDepleted: boolean, cid: Principal, k: number) => {
    if (isDepleted) {
      return message.warning("This miner is depleted")
    }
    const isConfirm = window.confirm(`Are you sure you want to spend ${upgradeAmount} ICP to upgrade this miner?`)
    if (!isConfirm) return
    const arr = [...upgradeLoading, k]
    message.info(`Please wait for approximately 2 minutes for the process to complete. During this time,please do not refresh the page.`)
    try {
      setUpgradeLoading(arr)
      await mainApi.old_for_new(cid)
      message.success("You need to wait 1 hour for the Staking Power to display")
    } catch (e: any) {
      message.warning(e.message ?? e)
    } finally {
      principal && await mainApi.initState(principal)
      const newArray = arr.filter(item => item !== k);
      setUpgradeLoading(newArray)
    }
  }

  const create = async () => {
    try {
      setLoading(true)
      message.info({
        content: "Please wait for approximately 2 minutes for the process to complete. During this time" +
          "please do not refresh the page.",
      })
      await mainApi.create_miner()
    } catch (e: any) {
      message.error(e.message)
    } finally {
      setLoading(false)
      refresh()
    }
  }


  return <div className={"mining"}>
    <div style={{display: "flex", width: "100%", gap: "10px"}}>
      <CommonButton text={"new miner"} handleClick={create} needLoading={true}/>
      <CommonButton text={"refresh"} handleClick={refresh} needLoading={true}/>
    </div>
    {width < 500 ? <MobileMinerList loading={upgradeLoading} upgrade={upgrade} stakingStatus={stakingStatus}/> :
      <MinerList loading={upgradeLoading} upgrade={upgrade} stakingStatus={stakingStatus} small={width < 920}/>}
  </div>
}

export const upgradeAmount = 0.7
const MinerList = React.memo(({small, stakingStatus, loading, upgrade}: {
  small: boolean,
  stakingStatus: string[] | undefined,
  loading: number[],
  upgrade: Function
}) => {
  const {miners, cycleBalance, blockMined, power, isNew} = useMinersStore()

  return <>
    <div className={"miner_list"}>
      <table>
        <thead>
        <tr>
          {miners && miners.length > 0 && title.map((e, i) => <th key={i}>{e}</th>)}
        </tr>
        </thead>
        <tbody>
        {miners?.map((v, k) => {
          let isDepleted = false
          const p = power ? power[k] : 1
          const pw = typeof p === "number" ? p.toFixed(2) : p
          const cycle = cycleBalance && cycleBalance[k] ? cycleBalance[k] : "-/-"
          let newCycle
          if (typeof cycle === "number") {
            if (cycle < cycleDeleted) isDepleted = true // 0.2 T
            newCycle = (cycle / 1e12).toFixed(3) + " Trillion"
          } else {
            newCycle = cycle
          }

          return <tr key={k}>
            <td style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}>
              <div style={{display: "flex"}}>
                {shortenString(v.toString(), small ? 5 : 10)}
                <Copy text={v.toString()}/>
              </div>
              {isNew ? <>
                {!isNew[k] ? loading.findIndex(e => e === k) === -1 ?
                  <Upgrade api={() => upgrade(isDepleted, v, k)}/> :
                  <Loading show={true}/> : null}
              </> : <Loading show={true}/>}

            </td>
            <td style={{color: isDepleted ? "#e6634f" : "var(--text-color)"}}>{isDepleted ? "Depleted" : newCycle}</td>
            <td
              style={{color: blockMined && blockMined[k] > 0 ? "white" : "var(--text-color)"}}>
              {blockMined ? blockMined[k] : "-/-"}
            </td>
            <td>{power ? (+pw === 1 ? "ICP subnet error" : pw) : "-/-"} </td>
            <td>{stakingStatus ? stakingStatus[k] : "-/-"} </td>
            {/*<td>{value[5]}</td>*/}
          </tr>
        })}
        </tbody>
      </table>
    </div>
    <div style={{display: "flex", justifyContent: 'center', marginTop: "20px"}}>
      <Loading show={miners === undefined}/>
    </div>
  </>
})


const MobileMinerList = React.memo(({stakingStatus, loading, upgrade}: {
  stakingStatus: string[] | undefined,
  loading: number[],
  upgrade: Function
}) => {
  const [show, setShow] = useState(-1)
  const {miners, cycleBalance, isNew, blockMined, power} = useMinersStore()

  return <>
    <div className={"mobile_miner_list"}>
      {miners && miners.length > 0 && <div className={"mobile_miner_head"}>
        Miners
      </div>}
      <div className={"mobile_miner_body"}>
        {miners?.map((v, k) => {
          let isDepleted = false
          const cycle = cycleBalance && cycleBalance[k] ? cycleBalance[k] : "-/-"
          let newCycle
          if (typeof cycle === "number") {
            if (cycle < cycleDeleted) isDepleted = true // 0.2 T
            newCycle = (cycle / 1e12).toFixed(3) + " Trillion"
          } else {
            newCycle = cycle
          }
          const p = power ? power[k] : 1
          const pw = typeof p === "number" ? p.toFixed(2) : p
          return <div key={k}>
            <div onClick={() => show === k ? setShow(-1) : setShow(k)}>
              {v.toString()}
              <div style={{display: "flex", alignItems: 'center'}}>
                {isNew ? <>
                  {!isNew[k] ? loading.findIndex(e => e === k) === -1 ?
                    <Upgrade api={(e) => {
                      e.stopPropagation()
                      upgrade(isDepleted, v, k)
                    }}/> :
                    <Loading show={true}/> : null}
                </> : <Loading show={true}/>}
                <Icon show={show === k}/>
              </div>
            </div>
            {show === k ? miners ? <Dropdown show={show === k} value={{
                "Cycle Balance": isDepleted ? "Depleted" : newCycle,
                "Block Mined": blockMined ? blockMined[k] : "-/-",
                "Power": power ? (+pw === 1 ? "ICP subnet error" : pw) : "-/-",
                "Staking Status": stakingStatus ? stakingStatus[k] : "-/-"
              }}/> :
              <div style={{display: "flex", justifyContent: 'center', marginTop: "20px"}}>
                <Loading show={miners === undefined}/>
              </div> : null}
          </div>
        })}
      </div>
    </div>
    <div style={{display: "flex", justifyContent: 'center', marginTop: "20px"}}>
      <Loading show={miners === undefined}/>
    </div>
  </>
})

const Icon = React.memo(({show}: { show: boolean }) => {
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    transform: show ? "rotate(180deg)" : "",
    transition: 'all 0.2s linear'
  }}>
    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         p-id="4236" width="20px" height="20px">
      <path
        d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3 0.1-12.7-6.4-12.7z"
        p-id="4237" fill="var(--border-color)"></path>
    </svg>
  </div>
})

const Dropdown = React.memo(({show, value}: { show: boolean, value: Record<string, any> }) => {
  const ks = Object.keys(value)
  const vs = Object.values(value)

  return <div style={{display: show ? "flex" : "none"}} className={"dropdown_wrap"}>
    {ks.map((v, k) => {
      return <div key={k}>
        {v} : {vs[k] instanceof Array ? vs[k].length : vs[k]}
      </div>
    })}
  </div>
})

export const Copy = React.memo(({text}: { text: string }) => {
  const [copied, setCopied] = React.useState(false)
  const copy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true)
    } catch (e) {
    } finally {
      setTimeout(() => setCopied(false), 1000)
    }
  }

  return <Tooltip title={copied ? "copied!" : "copy"}>
    <div style={{display: "flex", alignItems: 'center', cursor: "pointer"}} onClick={copy}>
      <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="4254" width="10px" height="10px">
        <path
          d="M878.250667 981.333333H375.338667a104.661333 104.661333 0 0 1-104.661334-104.661333V375.338667a104.661333 104.661333 0 0 1 104.661334-104.661334h502.912a104.661333 104.661333 0 0 1 104.661333 104.661334v502.912C981.333333 934.485333 934.485333 981.333333 878.250667 981.333333zM375.338667 364.373333a10.666667 10.666667 0 0 0-10.922667 10.965334v502.912c0 6.229333 4.693333 10.922667 10.922667 10.922666h502.912a10.666667 10.666667 0 0 0 10.922666-10.922666V375.338667a10.666667 10.666667 0 0 0-10.922666-10.922667H375.338667z"
          fill="var(--border-color)" p-id="4255"></path>
        <path
          d="M192.597333 753.322667H147.328A104.661333 104.661333 0 0 1 42.666667 648.661333V147.328A104.661333 104.661333 0 0 1 147.328 42.666667H650.24a104.661333 104.661333 0 0 1 104.618667 104.661333v49.962667c0 26.538667-20.309333 46.848-46.848 46.848a46.037333 46.037333 0 0 1-46.848-46.848V147.328a10.666667 10.666667 0 0 0-10.922667-10.965333H147.328a10.666667 10.666667 0 0 0-10.965333 10.965333V650.24c0 6.229333 4.693333 10.922667 10.965333 10.922667h45.269333c26.538667 0 46.848 20.309333 46.848 46.848 0 26.538667-21.845333 45.312-46.848 45.312z"
          fill="var(--border-color)" p-id="4256"></path>
      </svg>
    </div>
  </Tooltip>
})


const Upgrade = React.memo(({api}: { api: MouseEventHandler<HTMLDivElement> }) => {

  return <Tooltip title={"upgrade"}>
    <div style={{display: "flex", alignItems: 'center', cursor: "pointer"}} onClick={api}>
      <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="3158" width="20px" height="20px">
        <path
          d="M896 490.666667c-4.266667 0-10.666667-2.133333-14.933333-6.4L514.133333 155.733333 142.933333 484.266667c-8.533333 8.533333-21.333333 6.4-29.866666-2.133334-8.533333-8.533333-6.4-21.333333 2.133333-29.866666l386.133333-341.333334c8.533333-6.4 19.2-6.4 27.733334 0l379.733333 341.333334c8.533333 8.533333 8.533333 21.333333 2.133333 29.866666-4.266667 6.4-8.533333 8.533333-14.933333 8.533334zM640 832H384c-12.8 0-21.333333-8.533333-21.333333-21.333333s8.533333-21.333333 21.333333-21.333334h256c12.8 0 21.333333 8.533333 21.333333 21.333334s-8.533333 21.333333-21.333333 21.333333z"
          fill="#1afa29" p-id="3159"></path>
        <path
          d="M384 490.666667H128c-12.8 0-21.333333-8.533333-21.333333-21.333334s8.533333-21.333333 21.333333-21.333333h256c12.8 0 21.333333 8.533333 21.333333 21.333333s-8.533333 21.333333-21.333333 21.333334zM896 490.666667H640c-12.8 0-21.333333-8.533333-21.333333-21.333334s8.533333-21.333333 21.333333-21.333333h256c12.8 0 21.333333 8.533333 21.333333 21.333333s-8.533333 21.333333-21.333333 21.333334zM640 917.333333H384c-12.8 0-21.333333-8.533333-21.333333-21.333333s8.533333-21.333333 21.333333-21.333333h256c12.8 0 21.333333 8.533333 21.333333 21.333333s-8.533333 21.333333-21.333333 21.333333z"
          fill="var(--border-color)" p-id="3160"></path>
        <path
          d="M640 746.666667H384c-12.8 0-21.333333-8.533333-21.333333-21.333334V469.333333c0-12.8 8.533333-21.333333 21.333333-21.333333s21.333333 8.533333 21.333333 21.333333v234.666667h213.333334V469.333333c0-12.8 8.533333-21.333333 21.333333-21.333333s21.333333 8.533333 21.333333 21.333333v256c0 12.8-8.533333 21.333333-21.333333 21.333334z"
          fill="var(--border-color)" p-id="3161"></path>
      </svg>
    </div>
  </Tooltip>
})

