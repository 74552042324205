export const idlFactory = ({IDL}) => {
  const Commit = IDL.Record({
    'height': IDL.Nat64,
    'owner': IDL.Principal,
    'points': IDL.Float64,
  });
  const Result = IDL.Variant({'Ok': IDL.Principal, 'Err': IDL.Text});
  const Block = IDL.Record({
    'miner': IDL.Principal,
    'reward': IDL.Nat32,
    'timestamp_nanos': IDL.Nat64,
    'owner': IDL.Principal,
    'block_height': IDL.Nat64,
    'points': IDL.Float64,
  });
  const Blockchain = IDL.Record({
    'reward': IDL.Nat32,
    'adjustment_interval': IDL.Nat64,
    'target_time_per_block': IDL.Float64,
    'difficulty': IDL.Float64,
    'initial_reward': IDL.Nat32,
    'halving_interval': IDL.Nat64,
    'block_height': IDL.Nat64,
  });
  const Result_1 = IDL.Variant({'Ok': IDL.Null, 'Err': IDL.Text});
  const Tokens = IDL.Record({'e8s': IDL.Nat64});
  const Result_2 = IDL.Variant({'Ok': IDL.Nat64, 'Err': IDL.Text});
  return IDL.Service({
    'accept_commit': IDL.Func([Commit], [], []),
    'commit': IDL.Func([Commit], [], []),
    'create_miner': IDL.Func([], [Result], []),
    'cycle_balance': IDL.Func([], [IDL.Nat64], ['query']),
    'get_all_miners': IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'get_all_users': IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'get_block_by_height': IDL.Func([IDL.Nat64], [IDL.Opt(Block)], ['query']),
    'get_blockchain_info': IDL.Func([], [Blockchain], ['query']),
    'get_blocks': IDL.Func([IDL.Nat64], [IDL.Vec(Block)], ['query']),
    'get_failed_reward_tx': IDL.Func(
      [],
      [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat32))],
      ['query'],
    ),
    'get_global_miners_number': IDL.Func([], [IDL.Nat64], ['query']),
    'get_lock_time': IDL.Func([], [IDL.Nat64], ['query']),
    'get_lottery_users': IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'get_miner_list': IDL.Func(
      [IDL.Principal],
      [IDL.Vec(IDL.Principal)],
      ['query'],
    ),
    'get_staking_power': IDL.Func([], [IDL.Float64], ['query']),
    'old_for_new': IDL.Func([IDL.Principal], [Result_1], []),
    'reward_miner_to_community': IDL.Func(
      [IDL.Principal],
      [IDL.Principal],
      [],
    ),
    'start_miner': IDL.Func([IDL.Principal], [], []),
    'transfer_miner_owner': IDL.Func([IDL.Principal, IDL.Principal], [], []),
    'update_init_cycle': IDL.Func([IDL.Nat], [], []),
    'update_lock_time': IDL.Func([IDL.Nat64], [], []),
    'update_miner_wasm': IDL.Func([IDL.Vec(IDL.Nat8)], [], []),
    'update_new_miner_reward': IDL.Func(
      [IDL.Nat64, IDL.Nat64, IDL.Nat64],
      [],
      [],
    ),
    'update_staking_power': IDL.Func([IDL.Float64], [], []),
    'upgrade_miner': IDL.Func([IDL.Principal, IDL.Principal], [Result_1], []),
    'withdraw_icp': IDL.Func([IDL.Text, Tokens], [Result_2], []),
  });
};
export const init = ({IDL}) => {
  return [];
};
