import React, {useEffect, useState} from "react";
import "./index.scss"
import {Content} from "../Content";
import {useMinersStore} from "../../redux/features/miners";
import {chunkArray, removeDuplicatesAndCount, shortenString, timeDifferenceFromNow} from "../../utils/util";
import useWindowSize from "../../hook/Resize";
import PixelPieChartA from "./chart";
import {useDashboardStore} from "../../redux/features/dashboard";
import HorizontalBarChart from "./chart1";
import { Block } from "../../did/main/main";
import { mainApi } from "../../api/main";
import { Loading } from "../Button";
import { Principal } from "@dfinity/principal";

type T = {
  item: string,
  count: number | string
}

export const Dashboard = React.memo(() => {
  const {users, lotteryUsers, stakingWeight, globalMinersNumber, topMiners, blockChain, icpsPrice} = useDashboardStore()
  const {width} = useWindowSize()

  const [lottery, setLottery] = React.useState<T[]>([])
  const [topUser, setTopUser] = useState<T[]>([])


  const getLotteryUsers = async () => {
    if (!lotteryUsers) return
    const data = lotteryUsers.map(v => v.toString())
    const r = removeDuplicatesAndCount(data)
    r.sort((a, b) => b.count - a.count);
    const slice = r.slice(0, 20)
    setLottery(slice)
  }

  const getTopUser = async () => {
    if (!topMiners) return
    const data = topMiners.map(v => {
      return {
        item: v.miner_id.toString(),
        count: v.power.toFixed(4)
      }
    })
    setTopUser(data)
  }

  useEffect(() => {
    lotteryUsers && getLotteryUsers()
    topMiners && getTopUser()
  }, [lotteryUsers]);

  return <div className={"dashboard"}
              style={{display: "flex", flexDirection: width <= 750 ? "column" : "row", gap: "30px"}}>
    <div className={"part1"}>
      <Content title={"icps info"} li={[
        `ICPS: ${icpsPrice === undefined ? "-/-" : "$" + icpsPrice.toFixed(3)}`,
        "ICPS Total Supply: 10 million"

      ]} symbol={"point"}/>
      <Content title={"blockchain info"} li={[
        `User Count: ${users ? users.length : "-/-"}`,
        `Blocks Mined: ${blockChain ? Number(blockChain?.block_height) : "-/-"}`,
        `Total Miners: ${globalMinersNumber === undefined ? "-/-" : globalMinersNumber}`,
        `Staking Weight: ${stakingWeight === undefined ? "-/-" : stakingWeight.toFixed(4)}`,
        `Current Difficulty: ${blockChain ? blockChain.difficulty.toFixed(2) : "-/-"}`,
        `Reward: ${blockChain ? (blockChain.reward / 1e8) + " ICPS" : "-/-"}`,
        `Next Halving: ${blockChain ? Number(blockChain.halving_interval) : "-/-"}`
      ]} symbol={"point"}/>

      <Content title={"Miner Power Distribution Overview"}/>
      <HorizontalBarChart/>
      <Content title={"Lottery Winning Users"}/>
      <TowColumn title={["user id", "count"]} data={lottery}/>
      <Content title="Most Recent 10 Blocks"/>
      <LatestBlocks/>  
    </div>
    <div className={"part2"}>
      <Content title={"User Power Rankings"}/>
      <TowColumn title={["miner canister id", "power"]} data={topUser}/>
    </div>
  </div>
})

const TowColumn = React.memo(({title, data}: { title: string[], data: any[] }) => {
  return <table className={"lottery_users"}>
    <thead>
    <tr>
      <th>{title[0]}</th>
      <th>{title[1]}</th>
    </tr>
    </thead>
    <tbody>
    {data.map((v, k) => {
      return <tr key={k}>
        <td>{shortenString(v.item, 10)}</td>
        <td>{v.count}</td>
      </tr>
    })}
    </tbody>
  </table>
})

const LatestBlocks = React.memo(()=>{
  const [blocks,setBlocks] = useState<Block[]>()
  const [lotteryArr,setLotteryArr] = useState<string[][]>()
  const {blockChain,lotteryUsers} = useDashboardStore()

const getBlocks = async()=>{
  if(!blockChain) return
  const res = await mainApi.get_blocks(blockChain.block_height-BigInt(10))
  const Res = res.reverse()
  const slice = Res.slice(0, 10)
  setBlocks(slice)
}

const getLotteryArrs = ()=>{
if(!lotteryUsers) return
const strArr = lotteryUsers.map((v)=>v.toString())
const res = chunkArray<string>(strArr.reverse(),3)
setLotteryArr(res)
}

useEffect(()=>{
getLotteryArrs()
},[lotteryUsers])

  useEffect(()=>{
    getBlocks()
  },[blockChain])

  return <div className="blocks_wrap">
    {blocks?blocks.map((v,k)=>{
      return <div className="block" key={k}>
        <div className="title">Height : {Number(v.block_height)}</div>
        <div className="body">
          <div>To:{v.owner.toString()}</div>
          <div>Miner:{v.miner.toString()}</div>
          <div>Mining Rewards: {(v.reward/1e8 * 0.7).toFixed(2)} $ICPS</div>
          <div>Mined:{timeDifferenceFromNow(Number(v.timestamp_nanos))} ago</div>
          <div>Lottery Winning Users:
            {lotteryArr&&lotteryArr[k]?lotteryArr[k].map((v,k)=>{
              return <div key={v+k}>{v}</div>
            }):<>-/-</>}
          </div>
          <div>Lottery Rewards: {(v.reward/1e8 * 0.1).toFixed(2)} $ICPS Each</div>
        </div>
      </div>
    }): <Loading show={true} style={{width:"100%"}}/>    }

  </div>
})







